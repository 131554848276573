.heading-gradient {
    text-align: center;
    font-weight: bold;
    font-size: 42px;
    background: linear-gradient(to right, rgb(214, 230, 1), rgb(251, 3, 251), rgb(77, 255, 0));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .table-header {
    background-color: #004080;
    color: white;
    padding: 10px;
    font-weight: 800;
  }
  
  .table-category-header {
    background-color: #004080;
    color: white;
    padding: 10px;
    font-weight: 600;
  }
  
  .table-cell {
    border: 1px solid #004080;
  }
  
  .summary-table {
    margin-top: 20px;
    background-color: #004080;
    color: white;
    padding: 10px;
  }
  
  .summary-table-header {
    background-color: #004080;
    color: white;
    padding: 10px;
  }
  
  .text-center {
    text-align: center;
  }
  
  .mt-4 {
    margin-top: 20px;
  }
  