@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');



.mukta-extralight {
  font-family: "Mukta", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.mukta-light {
  font-family: "Mukta", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.mukta-regular {
  font-family: "Mukta", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.mukta-medium {
  font-family: "Mukta", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.mukta-semibold {
  font-family: "Mukta", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.mukta-bold {
  font-family: "Mukta", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.mukta-extrabold {
  font-family: "Mukta", sans-serif;
  font-weight: 800;
  font-style: normal;
}


body {
  margin: 0;
  font-family:  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 background-color: #FFFFFF !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


p, span, div, a, button, input, textarea {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

