/* Contact.css */

.office-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px; /* Space between the cards */
  }
  
  .office-card {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    flex: 1 1 calc(50% - 16px); /* Flex grow, flex shrink, flex basis */
    box-sizing: border-box;
  }
  
  .office-image {
    width: 120px;
    height: 120px;
    border-radius: 8px;
    margin-right: 16px;
  }
  
  @media (max-width: 800px) {
    .office-container {
      flex-direction: column;
    }
  
    .office-card {
      flex-direction: column;
      text-align: center;
    }
  
    .office-image {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
  