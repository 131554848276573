

#company, #mission, .section-alternate {
    /* background: linear-gradient(180deg, #e0e0e0 30%, #ffffff 90%); */
  }
  
  #vision, #values {
    /* background: linear-gradient(0deg, #e0e0e0 30%, #ffffff 90%); */
  }
  
  .section-image {
    width: 100%;
    height: auto;
    border-radius: 12px;
  }
  
  .section-image-alt {
    width: 100%;
    height: 100%;
    border-radius:12px;
  }
  
  .section-container {
    padding-top: 130px;
  }
  
  .section-padding {
    padding: 40px 0;
  }
  

  