/* .content {
    padding: 16px;
  }

  .content h1,
  .content h3 {
    display: inline-block;
    margin-right: 20px;
    color: #6fa8dc;
  }

  .content .section {
    display: flex;
    justify-content: space-around;
    border: 1px solid #d9d8d8;
    border-radius: 2px;
    background-color: #fff;
    padding: 5px;
    align-items: center;
    max-width: max-content;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);

  }

  .content .section div {
    flex: 1;
    margin-right: 20px;
  }

  .content .section div:last-child {
    margin-right: 0;
  } */


  .content {
    padding: 16px;
  }
  
  .content h1,
  .content h3 {
    display: inline-block;
    margin-right: 20px;
    color: #6fa8dc;
  }
  
  .content .section {
    display: flex;
    justify-content: space-around;
    border: 1px solid #d9d8d8;
    border-radius: 2px;
    background-color: #fff;
    padding: 5px;
    align-items: center;
    max-width: max-content;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }
  
  .content .section div {
    flex: 1;
    margin-right: 20px;
  }
  
  .content .section div:last-child {
    margin-right: 0;
  }
  
  