.navbar-container {
    width: 100%;
}

.toolbar-main {
    justify-content: space-between;
    background-color:var(--white) !important;
    backdrop-filter: blur(10px);
   
}

.search-bar {
    background-color: var(--primarywhite);
    padding: 10px 20px;
}

.Navbutton {
    padding: 0;
}

.drp-down-opt {
    color: var(--fontcolor) !important;;
    font-size: 14px !important;   
    font-weight: 500 !important;
    padding: 10px;
    cursor: pointer;
    font-family: var(--fontStyle) !important;
}

.drp-down-opt:hover{
    color: var(--blue) !important;
    background-color: transparent !important;  
}

.t-nav {
    background-color: #ffffff;
    min-height: 40px !important;
}

.drp-dwn-head {
    display: block;
    color: var(--fontcolor);
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 18px;
    white-space: nowrap;
    font-family:var(--fontStyle) !important;
}
.drp-dwn-head:hover {
    display: block;
    color: var(--buttonHover) !important;
    background-color: transparent !important;  
  
}


.navButtonStyle{
    color: var(--fontcolor) !important;
    padding: 0 15px;
    font-size: 15px !important;
    line-height: 20px;
    font-family: var(--fontStyle) !important;
    font-weight: 600 !important; 
    text-align: left;
    display: inline-block;
    text-decoration: none;
    /* border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent; */
}
.navButtonStyle:hover {
    color: var(--blue) !important;
    /* border-left :0.1px solid black;
    border-right: 0.1px solid black; */
    background: none !important;
    border-radius: 0px;
    
  }
 .techoptimalogo{
    max-height: 80px;
    width:auto;
  }






@media (max-width: 1440px) {
    .toolbar-main {
        flex-wrap: wrap;
    }
    .techoptimalogo{
        max-height: 80px;
        width:auto;
      }
    
}

@media (max-width: 1200px) {
    .toolbar-main {
        flex-wrap: wrap;
    }

    .techoptimalogo{
        max-height: 70px;
        width:200px;
      }
}
@media (min-width: 991px)  {
    .toolbar-main {
        flex-wrap: wrap;
    }

    .techoptimalogo{
        max-height: 65px;
        width:168px ;
      }
}

@media (max-width: 360px) {
    .navbar-container {
        width: 98%;
    }

    .techoptimalogo{
        max-height: 57px;
        width:156px;
      }
}

@media (max-width: 320px) {
    .navbar-container {
        width: 98%;
    }

    .techoptimalogo{
        
      }
}