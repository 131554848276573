@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
  /* --fontStyle: 'Inter', sans-serif; */
  --fontStyle: 'Open Sans', sans-serif;
  --fontcolor: #002856;
  /* --buttonPrimary: #7030C4; */
  --buttonPrimary: #ff7403;
  --optionsColor: #ff7403;
  --color1:#0000;
  --color2:#0077b5;

  --button-color: #FF2600;
  --buttonHover: #FF5000;
  --buttontextcolor: #FF2600;
  --secondary-button: #FF2600;
  --hover-Secondary-btn: #FF5000;
  --text-gradient: linear-gradient(270deg, #0099DD, #440362);
  --gradient2:linear-gradient(90deg, #1922c7, #eb0808);
  --boxshadow:#eb9494;

  /* --AI-engineering-card-color: #E6F7FF;
  --Data-science-card-color: #FFF4E6;
  --AI-Quality-card: #E6F4F1;
  --AI-assurence: #FFF0F1; */
  --AI-engineering-card-color: #b1e2f8;
  --Data-science-card-color: #fed8a6;
  --AI-Quality-card: #d8fe97;
  --AI-assurence: #9fffea;

  --points-blue: #000000;

  --vava: #003366;
  --backgroundcolor: #F5F5F5;
  --foterbackground: #FAFAFA;

  --hoverblue: #4e7eae;
  --background-color2:#f0dcd1;


  --black: #0000;
  --black-shade1: #eaeaea00;

  --blue: #FF2600;
  --white: #FFFFFF;
  --primarywhite: #f2f2f3;
  --lightblue: #0066cc;


  

  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --success-color: #28a745;
  --danger-color: #FF2600;
  --warning-color: #ffc107;
  --info-color: #17a2b8;
  --light-color: #f8f9fa;
  --dark-color: #8B5CF6;

  --background-color: #ffffff;


}

body {

  font-family: var(--fontStyle) !important;
  font-size: 14px;
  line-height: 1.42857143;
  color: var(--fontcolor) !important;
  background-color: var(--background-color) !important;
  font-feature-settings: "ss05" on;
}




h1,
h2,
h3,
h4,
h5,
h6 {
  color: #002856;
  text-decoration: none;
  font-family: var(--fontStyle) !important;
  font-weight: 700 !important;
}


p,
span,
div,
a,
button,
input,
textarea {
  font-family: var(--fontStyle) !important;
  font-weight: 400;
  color: var(--fontcolor);
}

Link {
  cursor: pointer;
  font-family: var(--fontStyle) !important;
  font-weight: 400;
  text-decoration: none;
}

Typography,
Button,
Text {
  font-family: var(--fontStyle) !important;
}


img{
  /* border-radius: 45px 0 45px 0; */
  width: 100%;
  /* height: 400px; */
}


.button {
  font-family: var(--fontStyle) !important;
  border: none;
  font-weight: bold;
  display: flex !important;
  align-items: center !important;
  padding: 12px 46px;
  margin-top: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.button.primary {
  background-color: none !important;
  color: var(--lightblue) !important;
}

.button.secondary {
  background-color: var(--secondary-color);
  color: var(--white);
}

.button.success {
  background-color: var(--success-color);
  color: var(--white);
}

.button.danger {
  background-color: var(--danger-color);
  color: var(--white);
}

.button:hover {
  opacity: 0.8;
}

.button:hover span {
  padding-left: 10px;
  transform: translateX(6px);
  transition: transform 0.1s ease-in-out;
}



@keyframes liquidFlow {
  0% {
    background-position: 0% 50%;
  }
  10% {
    background-position: 100% 50%;
  }
  0% {
    background-position: 0% 50%;
  }
}


