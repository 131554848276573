

   .header-container {
    width: 100%;
}
  
.header-top {
    background-color: var(--fontcolor);
   /* background-image: url(""); */
    color: var(--white);
    padding-top: 150px !important;
}
  
.header-title {
    background-color: var(--buttonPrimary);
    padding: 5px 10px;
    display: inline-block;
    margin-bottom: 10px;
    color: var(--white);
}
  
.header-button {
    background-color: var(--buttonPrimary);
    color: var(--white);
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    border-radius: none;
    font-weight: 400;
    line-height: 1.2;
    text-decoration: none;
}
  
.header-button:hover {
    background-color: var(--points-blue);
}
  

  
.image-container {
    text-align: center;
}
  
.responsive-image {
    width: 100%;
    height: auto;
}
  
.text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem; 
}
