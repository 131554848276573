.footer {
  background-color: rgba(197, 197, 197, 0.133);
  padding: 24px 0;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}
.footerLink{
  text-decoration:none !important;
  line-height: 1.5rem !important;
  font-weight: 500 !important;
}
.footerLink:hover{
  color: var(--blue) !important;
  color: #d4d3d167
}
.footerlogo{
  width: auto;
  height: 100px;
}
.css-bc1tov-MuiGrid-root {
 
  width: calc(100% + 1px) !important;
}
@media (max-width:1200px ){
  .footerlogo{
    width: 230px;
    height: 100px;
    margin-left: none;
  }
  .css-bc1tov-MuiGrid-root {
 
    width: calc(100% + 1px) !important;
  }
}

@media (min-width:768px ) and (max-width:991){
  .footerlogo{
    width: 200px;
    height: 75px;
    margin-left: none;
  }
  .css-bc1tov-MuiGrid-root {
 
    width: calc(100% + 1px) !important;
  }
}

@media (max-width:500px){
 .footerheader{
  font-family: 500px !important;
  font-size: 16px !important;
  color: var(--fontcolor) !important;
 }

 .footerLink{
  text-decoration:none !important;
  line-height: 2rem !important;
  font-weight: 500 !important;
}

}


